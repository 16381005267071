import React from 'react';
import { useTranslation, Trans } from '@getpopsure/i18n-react';

import { generateRadioInput } from 'pages/questions/generateInput';
import { WrappedForm } from 'pages/questions/formWrapper';
import { nonEmptyStringValidator } from 'validators';
import Center from 'components/center';
import { generalQuestionsTitleMapping } from 'models/questions/text/title';
import {
  residencePermitTypeMapping,
  residencePermitTypeSecondaryTestMapping,
} from 'models/questions/general/personalInfo';
import { InfoHint, InfoHintContainer } from 'components/infoHint';

export default () => {
  const { t } = useTranslation();

  return (
    <Center>
      <h1 className="p-h1">
        {generalQuestionsTitleMapping.whatTypeOfResidencePermit?.(t)}
      </h1>
      <InfoHintContainer className="mt8">
        <WrappedForm
          validator={nonEmptyStringValidator}
          sectionId="personalInfo"
          questionId="whatTypeOfResidencePermit"
          label={residencePermitTypeMapping(t)}
        >
          {({ value, setValue }) => (
            <>
              {generateRadioInput(
                'whatTypeOfResidencePermit',
                value,
                residencePermitTypeMapping(t),
                setValue,
                residencePermitTypeSecondaryTestMapping(t)
              )}
            </>
          )}
        </WrappedForm>
        <InfoHint
          title={t(
            'questions.typeofresidencepermit.hint.title',
            'What’s the difference?'
          )}
        >
          <Trans i18nKey="questions.typeofresidencepermit.hint.description">
            <p className="mb16">
              A limited residence permit or <i>Aufenthaltserlaubnis</i>{' '}
              (literally residence permit) is valid for a certain period of time
              and is issued for a certain purpose.
            </p>
            <p className="mb16">
              An unlimited residence permit or <i>Niederlassungserlaubnis</i>{' '}
              (literally settlement permit) is a permanent residence permit.
            </p>
            <p>
              A tourist visa allowing a stay up to 90 days is not a residence
              permit.
            </p>
          </Trans>
        </InfoHint>
      </InfoHintContainer>
    </Center>
  );
};
