import moment from 'moment';
import { TranslationFn } from '@getpopsure/i18n-react';

import { store } from 'reducers';
import { GeneralQuestionId } from 'models/questions/general';
import { dentalConditionMapping } from 'models/questions/general/medicalHistory';

export const explanationDefaultText = (t: TranslationFn) =>
  t(
    'questions.explanation.default.title',
    'Would you explain this to us in a little more detail?'
  );

const DATE_FORMAT = 'MMMM Do YYYY';

type UndefinedQuestions =
  | 'personalInfoReview'
  | 'insuranceHistoryV2Review'
  | 'insuranceLawDisclaimer'
  | 'dataProcessingDisclaimer'
  | 'financialHistoryReview'
  | 'selfEmploymentIntro'
  | 'medicalHistoryReview'
  | 'answersCheck'
  | 'finalSteps';

type NoTitleQuestionsOmitted = Exclude<GeneralQuestionId, UndefinedQuestions>;

type QuestionTitleType = {
  [k in NoTitleQuestionsOmitted]: (t: TranslationFn) => string;
} &
  {
    [j in UndefinedQuestions]: undefined;
  };

export const generalQuestionsTitleMapping: QuestionTitleType = {
  name: (t: TranslationFn) => t('questions.name.title', 'What is your name?'),
  email: (t: TranslationFn) =>
    t('questions.email.title', 'What is your email address?'),
  phoneNumber: (t: TranslationFn) =>
    t('questions.phonenumber.title', 'What is your mobile phone number'),
  dateOfBirth: (t: TranslationFn) =>
    t('questions.dateofbirth.title', 'What is your date of birth?'),
  gender: (t: TranslationFn) =>
    t('questions.gender.title', 'What is your gender?'),
  address: (t: TranslationFn) =>
    t('questions.address.title', 'What is your address?'),
  maritalStatus: (t: TranslationFn) =>
    t('questions.maritalstatus.title', 'What is your marital status?'),
  nationality: (t: TranslationFn) =>
    t(
      'questions.nationality.title',
      'Which countries do you hold a passport from?'
    ),
  relocationDate: (t: TranslationFn) =>
    t(
      'questions.relocationDate.title',
      'When did you or will you relocate to Germany?'
    ),
  livedInGermanyForLast36Months: (t: TranslationFn) =>
    t(
      'questions.livedInGermanyForLast36Months.title',
      'Have you lived in Germany for the last 36 months?'
    ),
  whatTypeOfResidencePermit: (t: TranslationFn) =>
    t(
      'questions.typeofresidencepermit.title',
      'What type of residence permit do you or will you have?'
    ),
  identificationDocument: (t: TranslationFn) =>
    t(
      'questions.identificationdocument.title',
      'Provide an identification document'
    ),
  passportNumber: (t: TranslationFn) =>
    t('questions.passportnumber.title', 'What is your passport number?'),
  passportExpiry: (t: TranslationFn) =>
    t('questions.passportexpiry.title', 'When does your passport expire?'),
  nationalIdNumber: (t: TranslationFn) =>
    t('questions.nationalidnumber.title', 'What is your national ID number?'),
  nationalIdExpiry: (t: TranslationFn) =>
    t('questions.nationalidexpiry.title', 'When does your national ID expire?'),
  personalInfoReview: undefined,
  employmentStatus: (t: TranslationFn) =>
    t('questions.employmenstatus.title', 'What is your employment status?'),
  employerName: (t: TranslationFn) =>
    t('questions.employername.title', 'What is the name of your employer?'),
  employmentStartDate: (t: TranslationFn) =>
    t(
      'questions.employmentStartDate.title',
      'What is the start date of your employment contract?'
    ),
  workContract: (t: TranslationFn) =>
    t(
      'questions.workcontract.title',
      'Please upload a signed copy of your work contract'
    ),
  income: (t: TranslationFn) =>
    t(
      'questions.income.title',
      'What is your annual income in Euros before taxes?'
    ),
  employedOutsideGermany: (t: TranslationFn) =>
    t(
      'questions.employedoutsidegermany.title',
      'Are you employed outside of Germany?'
    ),
  whatIsYourJobTitle: (t: TranslationFn) =>
    t('questions.jobtitle.title', 'What is your job title?'),
  workingAsFreelancerSince: (t: TranslationFn) => {
    const state = store.getState();
    const jobTitle = state.questionnaire?.financialHistory?.whatIsYourJobTitle;
    return t(
      'questions.freelancestartdate.title',
      'When did you start your self-employment as {{jobTitle}}?',
      { jobTitle }
    );
  },
  incomeEstimate: (t: TranslationFn) =>
    t(
      'questions.incomeestimate.title',
      'How much did you make before taxes and expenses during the last 12 months?'
    ),
  incomeExpectations: (t: TranslationFn) =>
    t(
      'questions.incomeexpectation.title',
      'How much do you expect to earn before taxes and expenses in the next twelve months?'
    ),
  previousActivity: (t: TranslationFn) =>
    t(
      'questions.previousactivity.title',
      'What did you do before you became self employed?'
    ),
  previousActivityExplanation: (t: TranslationFn) =>
    t('questions.previousactivityexplanation.title', 'Please explain'),
  monthlyIncomeBeforeTaxWhenEmployed: (t: TranslationFn) =>
    t(
      'questions.monthlyincomebeforetaxemployed.title',
      'What was your monthly income before tax when you were employed?'
    ),
  bankStatement: (t: TranslationFn) =>
    t('questions.bankstatement.title', 'Bank statements for the last 3 months'),
  incomeStatement: (t: TranslationFn) =>
    t('questions.incomestatement.title', 'Additional proof of income'),
  futureIncome: (t: TranslationFn) =>
    t('questions.futureincome.title', 'Proof of future income'),
  jobDescription: (t: TranslationFn) =>
    t('questions.jobdescription.title', 'Description of the work you do'),
  hasLinkedInProfile: (t: TranslationFn) =>
    t(
      'questions.linkedinprofile.title',
      'Do you have a LinkedIn profile you are willing to share?'
    ),
  linkedInProfileUrl: (t: TranslationFn) =>
    t(
      'questions.linkedinprofileurl.title',
      'Please share the link to your profile on LinkedIn.'
    ),
  hasWebsite: (t: TranslationFn) =>
    t('questions.haswebsite.title', 'Do you have a website?'),
  websiteUrl: (t: TranslationFn) =>
    t('questions.websitelink.title', 'What is your website?'),
  whatKindOfOtherEmployment: (t: TranslationFn) =>
    t(
      'questions.otheremploymenstatus.title',
      'Is your status any of the following?'
    ),
  hasGermanTaxId: (t: TranslationFn) =>
    t('questions.hasgermantaxid.title', 'Do you have a German tax ID?'),
  germanTaxId: (t: TranslationFn) =>
    t('questions.germantaxid.title', 'What is your German tax ID?'),
  schufaConsent: (t: TranslationFn) =>
    t('questions.consenttoschufa.title', 'Consent to submit Schufa'),
  financialHistoryReview: undefined,
  selfEmploymentIntro: undefined,
  mostRecentHealthInsurance: (t: TranslationFn) =>
    t(
      'questions.mostRecentHealthInsurance.title',
      'What was your most recent health insurance?'
    ),
  alreadyOnPublicViaEmployer: (t: TranslationFn) =>
    t(
      'questions.alreadyOnPublicViaEmployer.title',
      'Your employer already signed you up with public health insurance'
    ),
  whatWasCovered: (t: TranslationFn) =>
    t(
      'questions.whatWasCovered.title',
      'What was covered under this insurance?'
    ),
  hadGermanHealthProvider: (t: TranslationFn) =>
    t(
      'questions.hadGermanHealthProvider.title',
      'Is that insurance with a German health provider?'
    ),
  previousProviderName: (t: TranslationFn) =>
    t(
      'questions.previousProviderName.title',
      'What was the name of your most recent provider?'
    ),
  endOfPreviousHealthInsurance: (t: TranslationFn) =>
    t(
      'questions.endOfPreviousHealthInsurance.title',
      'When did your last health insurance end?'
    ),
  startDate: (t: TranslationFn) =>
    t(
      'questions.startDate.title',
      'When would you like your coverage to begin?'
    ),
  hasBeenHealthInsuredAtAllTimes: (t: TranslationFn) => {
    const startDate = store.getState().questionnaire.insuranceHistory
      ?.startDate;

    if (!startDate) {
      throw new Error('Can’t find a start date');
    }
    const startDateIsInFuture = moment(startDate).isAfter();

    const insuredSinceDate = startDateIsInFuture
      ? moment().subtract(12, 'months')
      : moment(startDate).subtract(12, 'months');

    return t(
      'questions.hasBeenHealthInsuredAtAllTimes.title',
      'Have you been health insured at all times since {{insuredSinceDate}}?',
      { insuredSinceDate: insuredSinceDate.format(DATE_FORMAT) }
    );
  },
  livingInGermany: (t: TranslationFn) => {
    const startDate = store.getState().questionnaire.insuranceHistory
      ?.startDate;

    if (!startDate) {
      throw new Error('Can’t find a start date');
    }
    const startDateIsInFuture = moment(startDate).isAfter();

    const formattedStartDate = moment(startDate).format(DATE_FORMAT);
    return startDateIsInFuture
      ? t(
          'questions.livingInGermany.future.title',
          'Will you be living in Germany on {{formattedStartDate}}?',
          { formattedStartDate }
        )
      : t(
          'questions.livingInGermany.present.title',
          'Are you currently living in Germany?'
        );
  },
  insuranceHistoryV2Review: undefined,
  insuranceLawDisclaimer: undefined,
  dataProcessingDisclaimer: undefined,
  heightInCm: (t: TranslationFn) =>
    t('questions.heightincm.title', 'How tall are you in centimeters?'),
  weightInKg: (t: TranslationFn) =>
    t('questions.weightinkg.title', 'What is your weight in kilograms?'),
  diagnosedTreatedOrGivenMedicalAdvice: (t: TranslationFn) =>
    t(
      'questions.diagnosedtreatedorgivenmedicaladvice.title',
      'Within in the past three years, have you been diagnosed, treated, or given medical advice by a medical professional or non-medical practitioner?'
    ),
  admittedToHospitalPast10years: (t: TranslationFn) =>
    t(
      'questions.admittedtohospital.title',
      'Within the last ten years, have you been admitted to a hospital for examinations, treatments or operations?'
    ),
  hivPositive: (t: TranslationFn) =>
    t(
      'questions.hivpositive.title',
      'Have you ever been diagnosed, treated or tested positive for HIV (Human Immunodeficiency Virus) or AIDS (Acquired Immune Deficiency Syndrome)?'
    ),
  hasReceivedPsychotherapy: (t: TranslationFn) =>
    t(
      'questions.receivedpsychotherapy.title',
      'Have you received psychotherapy or has it been recommended to you within the last ten years?'
    ),
  unableToProduceChildren: (t: TranslationFn) =>
    t(
      'questions.unabletoproducechildren.title',
      'Within the last three years, have you been unable to produce children although you tried or received medical treatment related to an unfulfilled wish for a child?'
    ),
  sufferedFromCondition: (t: TranslationFn) =>
    t(
      'questions.sufferedfromcondition.title',
      'Within the last three years, have you suffered from any of the following conditions (other than what you already told us)?'
    ),
  planToUndergoMedicalTreatment: (t: TranslationFn) =>
    t(
      'questions.plantoundergomedicaltreatment.title',
      'In the future, do you have plans or intentions to undergo any medical treatments?'
    ),
  planToUndergoMedicalTreatmentExplanation: (t: TranslationFn) =>
    explanationDefaultText(t),
  hasMissingBodyPart: (t: TranslationFn) =>
    t(
      'questions.hasmissingbodypart.title',
      'Do you have any body implants, artificial joints or other artificial devices that replace a missing body part?'
    ),
  missingBodyPart: (t: TranslationFn) =>
    t('questions.missingbodypart.title', 'Is it any of the following?'),
  howManyTeethAffectedByCondition: (t: TranslationFn) => {
    const state = store.getState();
    const dentalConditions = (
      state.questionnaire.medicalHistory?.dentalCondition ?? []
    ).map((k) => dentalConditionMapping(t)[k]);
    const isPlural = dentalConditions.length > 1;

    return `${dentalConditions.join(', ')}: ${t(
      'questions.howmanyteethaffected.title',
      'How many of your teeth are affected by'
    )} ${
      isPlural
        ? t('questions.howmanyteethaffected.plural', 'these conditions')
        : t('questions.howmanyteethaffected.singular', 'this condition')
    }`;
  },
  missinBodyPartExplanation: (t: TranslationFn) => explanationDefaultText(t),
  sufferingFromCondition: (t: TranslationFn) =>
    t(
      'questions.sufferingfromcondition.title',
      'Are you suffering from any of the following?'
    ),
  sufferingFromConditionExplanation: (t: TranslationFn) =>
    explanationDefaultText(t),
  hasTakenMedication: (t: TranslationFn) =>
    t(
      'questions.hastakenmedication.title',
      'Within the last three years, have you taken any medications on a regular basis (even if just for preventive purposes)?'
    ),
  hasTakenMedicationExplanation: (t: TranslationFn) =>
    explanationDefaultText(t),
  assistingVisionDevice: (t: TranslationFn) =>
    t(
      'questions.assistingvisiondevice.title',
      'Do you wear glasses or contact lenses or have they been recommended to you?'
    ),
  hasBeenAtHospitalBecauseOfVision: (t: TranslationFn) =>
    t(
      'questions.hasbeenathospitalbecauseofvision.title',
      'Have you ever been at a stationary stay at hospital because of your vision?'
    ),
  hadOperationBecauseOfVision: (t: TranslationFn) =>
    t(
      'questions.hasoperationbecauseofvision.title',
      'Did you ever have an operation related to your vision?'
    ),
  maximumEyeDiopters: (t: TranslationFn) =>
    t(
      'questions.maximumeyediopters.title',
      'What is the maximum lens prescription strength on your eyes?'
    ),
  undergoingTreatment: (t: TranslationFn) =>
    t(
      'questions.undergoingtreatment.title',
      'Are you currently undergoing any of the following treatments?'
    ),
  hasMissingTeeth: (t: TranslationFn) =>
    t(
      'questions.hasmissingteeth.title',
      'Do you have teeth missing from your natural jaw, that have not been replaced yet?'
    ),
  missingTeethUpperOrLowerJaw: (t: TranslationFn) =>
    t(
      'questions.missingteethupperorlowerjaw.title',
      'Are your missing teeth from your UPPER or LOWER jaw?'
    ),
  dentalCondition: (t: TranslationFn) =>
    t('questions.dentalcondition.title', 'Do you have any of the following?'),
  upperJawMissingTeeth: (t: TranslationFn) =>
    t(
      'questions.upperjawmissingteeth.title',
      'Choose the teeth missing from your UPPER jaw:'
    ),
  lowerJawMissingTeeth: (t: TranslationFn) =>
    t(
      'questions.lowerjawmissingteeth.title',
      'Choose the teeth missing from your LOWER jaw:'
    ),
  jawCondition: (t: TranslationFn) =>
    t('questions.jawcondition.title', 'Do you have any of the following?'),
  medicalHistoryReview: undefined,
  answersCheck: undefined,
  finalSteps: undefined,
};

export const generalQuestionsShortTitleMapping = (
  t: TranslationFn
): { [k in GeneralQuestionId]: string | undefined } => ({
  name: t('questions.name.shorttitle', 'Full name'),
  email: t('questions.email.shorttitle', 'Email'),
  phoneNumber: t('questions.telephone.shorttitle', 'Telephone'),
  dateOfBirth: t('questions.dateofbirth.shorttitle', 'Date of birth'),
  gender: t('questions.gender.shorttitle', 'Gender'),
  address: t('questions.address.shorttitle', 'Address'),
  maritalStatus: t('questions.maritalstatus.shorttitle', 'Marital status'),
  nationality: t('questions.nationality.shorttitle', 'Nationality'),
  relocationDate: t('questions.relocationDate.shorttitle', 'Relocation date'),
  livedInGermanyForLast36Months: t(
    'questions.livedInGermanyForLast36Months.shorttitle',
    'In Germany for last 36 months'
  ),
  whatTypeOfResidencePermit: t(
    'questions.typeofresidencepermit.shorttitle',
    'Type of residence permit'
  ),
  identificationDocument: t(
    'questions.identificationDocument.shorttitle',
    'Identification document'
  ),
  passportNumber: t('questions.passportnumber.shorttitle', 'Passport number'),
  passportExpiry: t('questions.passportexpiry.shorttitle', 'Passport expiry'),
  nationalIdNumber: t(
    'questions.nationalidnumber.shorttitle',
    'National Id number'
  ),
  nationalIdExpiry: t(
    'questions.nationalidexpiry.shorttitle',
    'National Id expiry'
  ),
  personalInfoReview: undefined,
  employmentStatus: t(
    'questions.employmentstatus.shorttitle',
    'Employment status'
  ),
  employerName: t('questions.employername.shorttitle', 'Employer name'),
  employmentStartDate: t(
    'questions.employmentStartDate.shorttitle',
    'Employment start date'
  ),
  workContract: t('questions.workcontract.shorttitle', 'Copy of work contract'),
  income: t('questions.income.shorttitle', 'Income'),
  employedOutsideGermany: t(
    'questions.employedoutsidegermany.shorttitle',
    'Employed outside Germany'
  ),
  whatIsYourJobTitle: t('questions.jobtitle.shorttitle', 'Job title'),
  workingAsFreelancerSince: t(
    'questions.freelancestartdate.shorttitle',
    'Self employed since'
  ),
  incomeEstimate: t('questions.incomeestimate.shorttitle', 'Income estimate'),
  incomeExpectations: t(
    'questions.incomeexpectation.shorttitle',
    'Income expectations'
  ),
  previousActivity: t(
    'questions.previousactivity.shorttitle',
    'Previous activity'
  ),
  previousActivityExplanation: t(
    'questions.previousactivityexplanation.shorttitle',
    'Previous activity detail'
  ),
  monthlyIncomeBeforeTaxWhenEmployed: t(
    'questions.monthlyincomebeforetaxemployed.shorttitle',
    'Monthly income before taxes when employed'
  ),
  bankStatement: t('questions.bankstatement.shorttitle', 'Bank statement'),
  incomeStatement: t(
    'questions.incomestatement.shorttitle',
    'Income statement'
  ),
  futureIncome: t('questions.futureincome.shorttitle', 'Future income'),
  jobDescription: t('questions.jobdescription.shorttitle', 'Job description'),
  hasLinkedInProfile: undefined,
  linkedInProfileUrl: t(
    'questions.linkedinprofile.shorttitle',
    'LinkedIn profile url'
  ),
  hasWebsite: undefined,
  websiteUrl: t('questions.websitelink.shorttitle', 'Website url'),
  whatKindOfOtherEmployment: t(
    'questions.otheremploymentstatus.shorttitle',
    'What kind of other employment'
  ),
  hasGermanTaxId: t(
    'questions.hasgermantaxid.shorttitle',
    'Has German tax id?'
  ),
  germanTaxId: t('questions.germantaxid.shorttitle', 'German tax id'),
  schufaConsent: '',
  selfEmploymentIntro: undefined,
  financialHistoryReview: undefined,
  mostRecentHealthInsurance: t(
    'questions.mostRecentHealthInsurance.shorttitle',
    'Most recent health insurance'
  ),
  alreadyOnPublicViaEmployer: t(
    'questions.alreadyOnPublicViaEmployer.shorttitle',
    'Employer already signed you up with public health insurance'
  ),
  whatWasCovered: t('questions.whatWasCovered.shorttitle', 'Type of coverage'),
  hadGermanHealthProvider: t(
    'questions.hadGermanHealthProvider.shorttitle',
    'German provider'
  ),
  previousProviderName: t(
    'questions.previousProviderName.shorttitle',
    "Provider's name"
  ),
  endOfPreviousHealthInsurance: t(
    'questions.endOfPreviousHealthInsurance.shorttitle',
    'Insurance ended on'
  ),
  startDate: t('questions.startDate.shorttitle', 'Start date'),
  hasBeenHealthInsuredAtAllTimes: t(
    'questions.hasBeenHealthInsuredAtAllTimes.shorttitle',
    'Insured at all times'
  ),
  livingInGermany: t(
    'questions.livingInGermany.shorttitle',
    'Living in Germany on start date'
  ),
  insuranceHistoryV2Review: undefined,
  insuranceLawDisclaimer: undefined,
  dataProcessingDisclaimer: undefined,
  heightInCm: t('questions.heightincm.shorttitle', 'Height (cm)'),
  weightInKg: t('questions.weightinkg.shorttitle', 'Weight (kg)'),
  diagnosedTreatedOrGivenMedicalAdvice: t(
    'questions.diagnosedtreatedorgivenmedicaladvice.shorttitle',
    'Diagnosed in the last 3 years?'
  ),
  admittedToHospitalPast10years: t(
    'questions.admittedtohospital.shorttitle',
    'Admitted to hospital in last 10 years?'
  ),
  hivPositive: t(
    'questions.hivpositive.shorttitle',
    'Diagnosed with HIV/AIDS?'
  ),
  hasReceivedPsychotherapy: t(
    'questions.receivedpsychotherapy.shorttitle',
    'Received Psychotherapy?'
  ),
  unableToProduceChildren: t(
    'questions.unabletoproducechildren.shorttitle',
    'Unable to produce children?'
  ),
  sufferedFromCondition: t(
    'questions.sufferedfromcondition.shorttitle',
    'Other conditions?'
  ),
  planToUndergoMedicalTreatment: t(
    'questions.plantoundergomedicaltreatment.shorttitle',
    'Future medical treatments?'
  ),
  planToUndergoMedicalTreatmentExplanation: t(
    'questions.plantoundergomedicaltreatmentexplanation.shorttitle',
    'Future medical treatments:'
  ),
  hasMissingBodyPart: t(
    'questions.hasmissingbodypart.shorttitle',
    'Missing body part or artificial implants?'
  ),
  missingBodyPart: t(
    'questions.missingbodypart.shorttitle',
    'Missing body part?'
  ),
  missinBodyPartExplanation: t(
    'questions.missingbodypart.explanation.shorttitle',
    'Missing body part:'
  ),
  sufferingFromCondition: t(
    'questions.sufferingfromcondition.shorttitle',
    'Chronic health problems?'
  ),
  sufferingFromConditionExplanation: t(
    'questions.sufferingfromcondition.explanation.shorttitle',
    'Chronic health problems:'
  ),
  hasTakenMedication: t(
    'questions.hastakenmedication.shorttitle',
    'Take regular medication?'
  ),
  hasTakenMedicationExplanation: t(
    'questions.hastakenmedication.explanation.shorttitle',
    'Take regular medication:'
  ),
  howManyTeethAffectedByCondition: t(
    'questions.howmanyteethaffected.shorttitle',
    'How many of your teeth are affected:'
  ),
  assistingVisionDevice: t(
    'questions.assistingvisiondevice.shorttitle',
    'Do you wear glasses or contact lenses?'
  ),
  hasBeenAtHospitalBecauseOfVision: t(
    'questions.hasbeenathospitalbecauseofvision.shorttitle',
    'Has been at hospital because of vision?'
  ),
  hadOperationBecauseOfVision: t(
    'questions.hadoperationbecauseofvision.shorttitle',
    'Had operation because of vision?'
  ),
  maximumEyeDiopters: t(
    'questions.maximumeyediopters.shorttitle',
    'Maximum eye diopters?'
  ),
  undergoingTreatment: t(
    'questions.undergoingtreatment.shorttitle',
    'Dentist or Orthodontic treatment?'
  ),
  hasMissingTeeth: t('questions.hasmissingteeth.shorttitle', 'Teeth Missing?'),
  missingTeethUpperOrLowerJaw: t(
    'questions.missingteethupperorlowerjaw.shorttitle',
    'Missing teeth UPPER or LOWER jaw?'
  ),
  upperJawMissingTeeth: t(
    'questions.upperjawmissingteeth.shorttitle',
    'Upper jaw missing teeth:'
  ),
  lowerJawMissingTeeth: t(
    'questions.lowerjawmissingteeth.shorttitle',
    'Lower jaw missing teeth:'
  ),
  dentalCondition: t(
    'questions.dentalcondition.shorttitle',
    'Any types of fake teeth?'
  ),
  jawCondition: t(
    'questions.jawcondition.shorttitle',
    'Any types of jaw condition?'
  ),
  medicalHistoryReview: undefined,
  answersCheck: undefined,
  finalSteps: undefined,
});
